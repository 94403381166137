<template>
  <div>
    <div class="search-con search-con-top">
      <div>
        <!-- 第一个选择器  选择用户类型  -->
        <Select
          v-model="userType"
          placeholder="选择用户类型"
          @on-change="handleKindUserTypeChange"
          style="width: 200px; margin-right: 20px; margin-bottom: 20px"
        >
          <Option
            v-for="item in signTypeList"
            :value="item.value"
            :key="item.value"
            >{{ item.value }}</Option
          >
        </Select>
        <!-- 第二个选择器  搜索用户名 -->
        <Input
          v-model="username"
          placeholder="请输入用户名"
          @on-enter="handleSearch"
          @on-clear="handleClearInput"
          clearable
          style="width: 250px; margin-right: 20px; margin-bottom: 20px"
        />
        <Button
          @click="handleSearch"
          class="search-btn"
          style="margin-right: 20px; margin-bottom: 20px"
          type="primary"
          ><Icon type="ios-search" />&nbsp;&nbsp;搜索</Button
        >
        <Button
          class="search-btn"
          v-if="privilege >= 2"
          type="success"
          style="margin-bottom: 20px"
          @click="addUser()"
          >新建用户</Button
        >
      </div>
      <Table :data="tableUserNumberData" :columns="tableUserColumns" stripe>
        <template slot-scope="{ row }" slot="action">
          <Button
            type="primary"
            size="small"
            style="margin: 5px"
            @click="updateUserModal(row)"
            >修改用户</Button
          >
        </template>
      </Table>
      <div style="margin: 10px; overflow: hidden">
        <div style="display: flex; justify-content: flex-end">
          <Page
            :total="totalUserCount"
            :current="1"
            @on-change="changePage"
            :pageSize="pageSize"
          ></Page>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import moment from "moment";
// import { Input } from 'view-design';
export default {
  name: "manageUser",
  data() {
    return {
      userType: "所有用户",
      signTypeList: [
        { value: "所有用户" },
        { value: "student" },
        { value: "teacher" },
        { value: "advisor" }
      ],
      tableUserNumberData: [],
      tableUserColumns: [
        {
          title: "用户名",
          key: "username",
          sortable: true
        },
        {
          title: "昵称",
          key: "display_name",
          sortable: true
        },
        {
          title: "用户类型",
          key: "user_type",
          sortable: true
        },
        {
          title: "用户权限",
          key: "privilege",
          sortable: true
        },
        {
          title: "Action",
          slot: "action",
          width: 150,
          align: "center"
        }
      ],
      pageSize: 9,
      totalUserCount: 0,
      totalData: [],
      username: "",
      note: ""
    };
  },
  methods: {
    handleKindUserTypeChange(selectedType) {
      let url = "api/users";
      if (selectedType !== "所有用户") {
        url += "?userType=" + selectedType;
      }
      this.axios.get(url).then(resp => {
        const respData = resp.data;
        this.setData(respData);
      });
    },
    changePage(currentPage) {
      // console.log(currentPage)
      const start = (currentPage - 1) * this.pageSize;
      const end = currentPage * this.pageSize;
      this.tableUserNumberData = this.totalData.slice(start, end);
    },
    handleSearch() {
      if (this.username.length === 0) {
        this.handleClearInput();
      } else {
        const result = this.totalData.filter(
          item => item.username === this.username
        );
        this.tableUserNumberData = result;
        this.totalUserCount = result.length;
      }
      //onsole.log(result);
    },
    //显示全部
    handleClearInput() {
      this.setData(this.totalData);
    },
    addUser() {
      this.$router.push("/addUser");
    },
    confirmUserModal(row) {
      // console.log(row.username)
      this.$Modal.confirm({
        render: () => {
          return (
            <div style="display: flex; flex-direction:column; margin-top: 20px;">
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">学生用户名:</span>{" "}
                &nbsp;&nbsp;{row.student}
              </div>
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">报名科目:</span> &nbsp;&nbsp;
                {row.course_code}
              </div>
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">学校名称:</span> &nbsp;&nbsp;
                {row.campus}
              </div>
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">学院名称:</span> &nbsp;&nbsp;
                {row.faculty}
              </div>
              <div style="font-size: 16px;margin: 6px 0; display: flex;">
                <div style="font-weight: bold; ">备注:</div> &nbsp;&nbsp;
                <input id={row.username} style="width: 250px;" />{" "}
              </div>
            </div>
          );
        },
        title: `确认收到订单号为${row.username}的转账`,
        loading: true,
        onCancel: () => {
          this.$Modal.remove();
        },
        cancelText: "取消",
        onOk: async () => {
          const { username } = row;
          const note = document.getElementById(username).value;
          const advisorNote = { advisor_note: note };
          try {
            const resp = await this.axios.post(
              `api/advisor/courses/signed/${username}/paid`,
              advisorNote
            );
            this.$Message.success(
              `已成功确认订单号为${resp.data.username}的付款。`
            );
          } catch {
            this.$Message.error("确认付款失败");
          } finally {
            this.$Modal.remove();
            this.handleKindUserTypeChange("allType");
          }
        }
      });
    },
    removeUserModal(row) {
      // console.log(row.username);
      this.$Modal.confirm({
        title: `<h2>Username ${row.username}</h2>`,
        content: `<div>Are you sure to delete ${row.userType} ${row.username}?</div>
        `,
        onCancel: () => {
          this.$Modal.remove();
        },
        onOk: async () => {
          const { username } = row;
          try {
            const resp = await this.axios.delete(`api/users/${username}`);
            if (resp.status === 204) {
              this.$Message.success(`您已经成功删除了${row.username}用户!`);
            }
            this.axios.get("api/users").then(resp => {
              // console.log(resp.data)
              this.orderData = resp.data;
            });
          } catch {
            this.$Message.error("删除用户失败");
          } finally {
            this.$Modal.remove();
          }
        }
      });
      return row.username;
    },
    updateUserModal(row) {
      const username = row.username;
      this.$router.push({
        path: "/edituser",
        query: {
          id: username
        }
      });
      // return row.username;
    },
    quitCourseModal(row) {
      // console.log(row.username);
      return row.username;
    },
    setData(respData) {
      this.totalUserCount = respData.length;
      this.totalData = respData.reverse();
      if (respData.length < this.pageSize) {
        this.tableUserNumberData = respData;
      } else {
        this.tableUserNumberData = respData.slice(0, this.pageSize);
      }
    }
  },
  computed: {
    privilege: function () {
      return this.$store.getters.getCurrentUser.privilege;
    }
  },
  mounted: function () {
    this.axios.get("api/users").then(resp => {
      const respData = resp.data;
      this.setData(respData);
    });
  }
};
</script>
